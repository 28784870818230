
import { Component, Vue } from "vue-property-decorator";
import { categoryList, login } from "../api/ApiPath";

@Component
export default class Login extends Vue {
  name: string = "login";
  LoginForm = {
    username: "",
    email: "",
    password: "",
    code: "",
  };
  login() {
    localStorage.setItem("token", "");
    const { from } = this;

    if (this.LoginForm.username == "" && this.LoginForm.password == "") {
      this.$message.error("用户名或者密码必须填");
    } else {
      login(
        this.LoginForm,
        (res) => {
          const { data, status, msg } = res;
          if (status === 200) {
            var token = JSON.stringify(res.data[0]);
            localStorage && localStorage.setItem("token", token);
            this.$message.success(msg);
            if (from) {
              // history.go(-1)
              location.href = from;
            } else {
              location.href = "/";
            }
          } else {
            this.$message.error(msg);
          }
        },
        (err) => {
          this.$message.error(err);
        }
      );
    }
  }

  from: string;
  mounted() {
    const { query } = this.$route;
    const { from } = query;
    if (this.from != undefined && this.from != "") {
      this.from = from.toString();
    } else {
      this.from = "";
    }
  }
}
